import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, Link, Paper, Typography, useTheme } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { routes } from "routes";
import useSharedStyles from "components/useSharedStyles";
import { Link as RouterLink } from 'react-router-dom';
import { compareAsc, format, parse } from "date-fns";
import { ISalesProfile } from "./ISalesProfile";
import { useTracking } from "context/TrackingProvider";
import useDialogState from "hooks/useDialogState";
import { EditSubscriptionDialog } from "pages/godmode/Users/Teachers/ViewUser/subscription/EditSubscriptionDialog";
import { ChangeAdminDialog } from "pages/godmode/Users/Teachers/ViewUser/subscription/ChangeAdminDialog";
import { ReactivateSubscriptionDialog } from "pages/godmode/Users/Teachers/ViewUser/subscription/ReactivateSubscriptionDialog";
import { getQuoteNumber } from "pages/sales/quotes/getQuoteNumber";
import React from "react";

export const PlanHistoryCard: React.VFC<{ planHistory: ISalesProfile['plan_history'][0], salesProfile: ISalesProfile, godmode?: boolean }> = ({ planHistory, salesProfile, godmode }) => {
  const { has_curriculum, start_date, end_date, num_sites, active, paid, renewed, quickbooks_invoice_link, payment_due, delinquent } = planHistory;
  const sharedClasses = useSharedStyles();
  const theme = useTheme();
  const startDate = parse(start_date, 'yyyy-LL-dd', new Date());
  const endDate = parse(end_date, 'yyyy-LL-dd', new Date());
  const startsInFuture = compareAsc(startDate, new Date()) === 1;
  const expired = !delinquent && compareAsc(new Date(), endDate) === 1;
  const { track } = useTracking();
  const changeAdminDialogState = useDialogState();
  const reactivateSubscriptionDialogState = useDialogState();

  return <Box component={Paper} {...{ variant: 'outlined' }} p={2} display="flex" flexDirection="column">
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography variant="subtitle1">
          {active && <Chip size="small" style={{ background: theme.palette.green.main, color: 'white' }} label="Active" />}
          {startsInFuture && <Chip size="small" label="Upcoming" />}
          {delinquent && <Chip size="small" label="Downgraded" style={godmode ? { background: theme.palette.red.main, color: 'white' } : undefined} />}
          {expired && <Chip size="small" label="Expired" />}
          &nbsp;
          Kodable Premium{has_curriculum ? "+" : ""}
        </Typography>
        <Typography>{format(startDate, 'PP')} - {format(parse(end_date, 'yyyy-LL-dd', new Date()), 'PP')}</Typography>
        <Typography>{num_sites} license{num_sites > 1 ? 's' : ''}</Typography>
        {quickbooks_invoice_link && <Link href={quickbooks_invoice_link} target="_blank">View {paid ? "Paid" : ""} Invoice {godmode && payment_due ? `Due ${format(new Date(payment_due), 'PP')}` : ''}</Link>}
        {godmode && <>
          {salesProfile?.quotes.map((quote, i, { length }) => {
            return <><Link component={RouterLink} style={{ fontWeight: 'normal' }} to={routes.sales.quotes.view(quote.secret_code)}>Premium{quote.has_curriculum ? '+' : ''} x {quote.num_sites} on {format(new Date(quote.created_at), 'PP')} ({getQuoteNumber(quote)})</Link>{i < length - 1 ? ', ' : ''}</>
          })}
        </>}
      </Box>
      {!godmode && <Box display="flex" flexDirection="column" className={sharedClasses.vspacing1} justifyContent="center" width={200} textAlign="center">
        {!startsInFuture && !renewed && paid && <>
          <Button
            variant="contained"
            color="green"
            onClick={() => {
              track('Clicked on "Renew Now" button', {}, ['amplitude', 'intercom'])
            }}
            {...{
              component: RouterLink,
              to: routes.sales.orders.submitWithTargetTeacherId(salesProfile!.license_holder!.id)
            }}
          >
            {active ? "Renew" : "Reactivate"} Now
          </Button>
          <Link component={RouterLink} {...{ to: routes.sales.quotes.createRenewal(salesProfile!.license_holder!.id) }} className={sharedClasses.hoverCursorPointer}>
            Get {active ? "Renewal " : ""}Quote
          </Link>
        </>}
        {active && renewed && <Typography variant="h2" align="center">
          <FontAwesomeIcon icon={faCheck} color={theme.palette.green.main} /><br />
          Renewed!
        </Typography>}
        {!paid && quickbooks_invoice_link && <>
          <Button variant="contained" color={delinquent ? "red" : "orange"} href={quickbooks_invoice_link} {...{ target: '_blank' }}>
            {delinquent ? "Pay Overdue Invoice" : "Pay Invoice"}
          </Button>
          {payment_due && <Typography align="center" color={delinquent ? 'error' : 'textPrimary'} variant="body2">Due on {format(parse(payment_due, 'yyyy-MM-dd', new Date()), 'PPP')}</Typography>}
        </>
        }
      </Box>}
    </Box>

  </Box>;
}